import { dateFormatMMddyyyy } from './format';
import { AccountStatus, Enrollment } from 'src/types/enrollment';
import { NextPaymentData, ScheduledPayment } from 'src/types/payments';
import { getProgramName } from './get-program-name';
import { cms } from './language';

// Utility function to get the most relevant enrollment for a payment
export const getPaymentEnrollment = (
  scheduledPayments: ScheduledPayment[] | undefined,
  enrollments: Enrollment[] | undefined,
  defaultEnrollment: Enrollment | undefined
): Enrollment | undefined => {
  if (!scheduledPayments || scheduledPayments.length === 0) {
    return defaultEnrollment;
  }
  return (
    enrollments?.find(
      (e) => e.program?.programName === scheduledPayments[0].programName && !e.accountStatus.paymentsBlocked
    ) ?? defaultEnrollment
  );
};

// Mapping account status to UX status
export const getUxStatus = (accountStatus: AccountStatus, learningEnabled: boolean): string => {
  const statusMapping: Record<string, string> = {
    CURRENT: 'current',
    INCOLL: 'suspended',
    PIF: 'paid',
    PASTDUE: 'pastdue',
    COLLRDY: 'suspended',
    ARREARS: 'suspended',
    BANKRUPT: 'deactivated',
    WRITEOFF: learningEnabled ? 'settled' : 'write off',
  };

  return statusMapping[accountStatus?.code] || 'current';
};

// Static payment status
export const paidDescription = 'Your payment plan is paid. You have no scheduled payments at this time.';
export const paidStatus = 'paid';

// Function to get a formatted due date
const getFormattedDueDate = (dueDate: string | Date): string => {
  const date =
    dueDate instanceof Date ? dueDate.toISOString().split('T')[0] : new Date(dueDate).toISOString().split('T')[0];
  return dateFormatMMddyyyy(date);
};

// Refactored getPaymentDescription function
export const getPaymentDescription = (
  status: string,
  nextPaymentData: NextPaymentData,
  enrollment?: Enrollment
): string => {
  const programName = enrollment ? getProgramName(enrollment) : nextPaymentData?.programName ?? '';
  const formattedDueDate = getFormattedDueDate(nextPaymentData?.dueDate);
  const content = cms.content.pages.dashboard.cards.my_payments;
  const { replaceVariables } = cms;

  // Define replacements for variables in descriptions
  const replacements = [
    { key: 'programName', value: programName },
    { key: 'formattedDueDate', value: formattedDueDate },
  ];

  // Define descriptions in a lookup object
  const descriptions: Record<string, string | ((replacements: any[]) => string)> = {
    suspended: () => replaceVariables(content.payment_description_suspended, replacements),
    current: () => {
      if (nextPaymentData.paymentScheduleType === 'fee') {
        return replaceVariables(content.payment_description_current_fee, replacements);
      }
      return nextPaymentData.paymentPlanType !== 'standard'
        ? replaceVariables(content.payment_description_current_auto, replacements)
        : replaceVariables(content.payment_description_current_standard, replacements);
    },
    pastdue: () =>
      replaceVariables(
        nextPaymentData.paymentScheduleType !== 'fee'
          ? content.payment_description_past_due
          : content.payment_description_past_due_fee,
        replacements
      ),
    paid: () => replaceVariables(content.payment_description_paid, [{ key: 'programName', value: programName }]),
    deactivated: () => replaceVariables(content.payment_description_deactivated, [{ key: 'programName', value: programName }]),
  };

  // Retrieve the appropriate description
  const selectedDescription = descriptions[status] || content.payment_description_default;

  // Ensure that we only call the function if it's callable; otherwise, return as string
  return typeof selectedDescription === 'function' ? selectedDescription(replacements) : selectedDescription;
};
